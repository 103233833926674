// auth.service.js
/* globals angular */
"use strict";

class authService {
  constructor($window) {
    this.$window = $window;
      this.authData = undefined;
  }

  getAccessTokenHeader() {
    if (!this.authData) {
        return;
    }
    return this.capitalize(this.authData.tokenType) + ' ' + this.authData.accessToken;
  }

  setAuthData(authData, teci) {
    authData.teci = teci;
    this.authData = Object.assign({}, authData);
    this.$window.sessionStorage.setItem("sweno.employee", angular.toJson(authData));
  }

  resetAuthData() {
    this.$window.sessionStorage.removeItem("sweno.employee");
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

authService.$inject = ["$window"];

export { authService as AUTH_SERVICE };