// bootstrap.js

"use strict";

import angular from "angular";
import { EMPLEADOS_LOGIN_SWENO_ENERGIA } from "../app";

angular.bootstrap(document, [EMPLEADOS_LOGIN_SWENO_ENERGIA], {
  strictDi: true
});
