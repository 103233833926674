// app.states.js

"use strict";

const loginWrapper = {
  name: "loginWrapper",
  url: "/login?p=&d=",
  component: "loginWrapper",
  lazyLoad: ($transition$) => {
    const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
    return import(/* webpackChunkName: "login-wrapper.module" */ "./components/login-wrapper/login-wrapper.module")
      .then(mod => $ocLazyLoad.inject(mod.LOGIN_WRAPPER_MODULE))
      .catch(err => {
        throw new Error("Ooops, something went wrong, " + err);
      });
  },
  onEnter: ["$document", ($document) => {
    $document[0].title = "Login";
    $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Ya eres cliente de Energia El Corte Ingles');
    $document[0].querySelector('meta[name="keyword"]').setAttribute('content', '');
    $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://contratacion.energiaelcorteingles.es/login');
  }]
};

const loginWrapperNoSuffix = {
  name: "loginWrapperNoSuffix",
  url: "/login",
  component: "loginWrapper",
  lazyLoad: ($transition$) => {
    const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
    return import(/* webpackChunkName: "login-wrapper.module" */ "./components/login-wrapper/login-wrapper.module")
      .then(mod => $ocLazyLoad.inject(mod.LOGIN_WRAPPER_MODULE))
      .catch(err => {
        throw new Error("Ooops, something went wrong, " + err);
      });
  },
  onEnter: ["$document", ($document) => {
    $document[0].title = "Login";
    $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Ya eres cliente de Energia El Corte Ingles');
    $document[0].querySelector('meta[name="keyword"]').setAttribute('content', '');
    $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://contratacion.energiaelcorteingles.es/login');
  }]
};

const errorProducto = {
  name: "errorProducto",
  url: "/login/errorProducto",
  component: "errorProducto",
  lazyLoad: ($transition$) => {
    const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
    return import(/* webpackChunkName: "errorProducto.module" */ "./components/error-producto/error-producto.module")
      .then(mod => $ocLazyLoad.inject(mod.ERROR_PRODUCTO_MODULE))
      .catch(err => {
        throw new Error("Ooops, something went wrong, " + err);
      });
  },
  onEnter: ["$document", ($document) => {
    $document[0].title = "Energía El Corte Inglés - Error";
    $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Contrata Energia El Corte Ingles');
    $document[0].querySelector('meta[name="keyword"]').setAttribute('content', '');
    $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://ofertaempleados.energiaelcorteingles.es/errorProducto');
  }]
};

export { loginWrapper, loginWrapperNoSuffix, errorProducto };
