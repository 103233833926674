// cookies.controller.js
"use strict";

class cookiesController {
  constructor(cookiesService, ngDialog, $stateParams) {
    this.$stateParams = $stateParams;
    this.service = cookiesService;
    this.ngDialog = ngDialog;
    this.analytics = true;
    this.consent = {
      statistics: false,
    };
    this.show = this.service.show();
    this.dialogoCookies;
  }

  $onInit() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("d");

    if (myParam === "t") {
      this.service.storePreferences(this.consent);
      this.service.setShow(false);
      this.show = false;
    }

    if (this.show) {
      this.dialogoCookies = this.ngDialog.open({
        template:
          '<cookies-dialog data-accept="ngDialogData.onaccept(analytics)"></cookies-dialog>',
        className: "ngdialog-theme-default ngdialog-responsive",
        plain: true,
        showClose: false,
        closeByEscape: false,
        closeByDocument: false,
        data: {
          onaccept: this.onaccept(this.analytics),
        },
      });
    }
  }

  onaccept() {
    return (analytics) => {
      this.analytics = analytics;
      this.dialogoCookies.close();
      this.selection(true);
    };
  }

  $onChanges() {}

  selection(value) {
    this.service.setShow(false);
    this.show = false;
    this.consent.statistics = value;
    this.service.storePreferences(this.consent);
    if (this.analytics) {
      this.service.startRegisteringEvents();
    }
  }
}

cookiesController.$inject = ["cookiesService", "ngDialog", "$stateParams"];

export { cookiesController as COOKIES_CONTROLLER };
