// app.routing.js

"use strict";

import { loginWrapper, loginWrapperNoSuffix, errorProducto } from "./app.states";

appRouting.$inject = ["$stateProvider", "$urlRouterProvider", "$locationProvider", "$urlMatcherFactoryProvider"];

function appRouting($stateProvider, $urlRouterProvider, $locationProvider, $urlMatcherFactoryProvider) {
  $urlMatcherFactoryProvider.strictMode(false);
  $locationProvider.html5Mode(true);
  $urlRouterProvider.otherwise("/login");
  $stateProvider.state(loginWrapper);
  $stateProvider.state(loginWrapperNoSuffix);
  $stateProvider.state(errorProducto);
}

export { appRouting };
